import React, { Fragment, useEffect, useState } from 'react';
import { Container, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ITeaser } from '../types';
import Teaser from '../reusable/Teaser';
import CustomPagination from '../reusable/CustomPagination';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { range } from '../../context/actions/common';
import { searchByIds } from '../../context/actions/article';
import { getAllInterviews } from '../../context/actions/home';

interface Props {}

const SeeAllInterviews = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const itemsPerPage: number = 10;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ids, setIds] = useState<string[]>([]);
  const [teasers, setTeasers] = useState<ITeaser[]>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setIsLoading(true);

    getAllInterviews()
      .then((res: any) => res?.data)
      .then((data: any) => setIds(data))
      .catch(() => setIds([]));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const targetIds = ids
      .slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage)
      .map((el: any) => el.documentid);

    if (targetIds?.length > 0) {
      searchByIds(targetIds)
        .then((res: any) => res?.data)
        .then((data: any) => {
          data.sort((a: ITeaser, b: ITeaser) => {
            // Parse dates in the format "dd-mm-yyyy" if documentdate is defined
            const dateA = a.documentdate ? new Date(
              a.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            const dateB = b.documentdate ? new Date(
              b.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            // Compare the dates
            return dateB - dateA; // Sort in descending order
          });          
          setTeasers(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setTeasers([]);
          setIsLoading(false);
          console.log('Oops: ', err);
        });
    }
  }, [ids, page]);

  return (
    <div className='section__pagination'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Videos/&#8203;Webcasts</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Startseite
          </Link>
        </div>
        <div className='section__light'>
          {isLoading ? (
            range(itemsPerPage).map((el: number, key: number) => (
              <Fragment key={key}>
                {key !== 0 && <Divider style={{ margin: deviceXs ? '16px 0' : deviceSm ? '24px 0' : '32px 0' }} />}
                <TeaserSkeleton />
              </Fragment>
            ))
          ) : teasers.length > 0 ? (
            teasers.map((teaser: ITeaser, key: number) => (
              <Fragment key={teaser.documentid}>
                {key !== 0 && <Divider style={{ margin: deviceXs ? '16px 0' : deviceSm ? '24px 0' : '32px 0' }} />}
                <Teaser teaser={teaser} />
              </Fragment>
            ))
          ) : (
            <span className='no-results-msg'>Keine Videos/&#8203;Webcasts</span>
          )}
        </div>
      </Container>
      <Container maxWidth='md'>
        <CustomPagination
          count={ids?.length > 0 ? Math.ceil(ids.length / itemsPerPage) : 1}
          page={page}
          setPage={setPage}
        />
      </Container>
    </div>
  );
};

export default SeeAllInterviews;
